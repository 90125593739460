import React, { useState, useEffect } from "react";
import "./style.css";
import { GiVote } from "react-icons/gi";
import logo from "../../images/logo_unimed.png";
import { getJurado, getToken, jurado, token } from "../../services/auth";
import { useHistory } from "react-router-dom";
import { AlertDialog } from "../../components/AlertDialog";
import { AlertSucess } from "../../components/AlertSucess";
import axios from "axios";
import FlatList from "flatlist-react";
import { FiLogOut } from "react-icons/fi";

function Votacao() {
  const [votacaoAtiva, setVotacaoAtiva] = useState();
  const [candidatos, setCandidatos] = useState([]);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertSucess, setAlertSucess] = useState(false);
  const [response, setResponse] = useState();
  const [candidatoSelecionado, setCandidatoSelecionado] = useState();
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("jurado");
    history.push("/");
  };

  const getData = () => {
    axios
      .get("https://unimedsudoeste.net.br/votacao/rstVotacaoAtiva.rule?sys=VOT")
      .then((response) => {
        if (response.data.status === "success") {
          setVotacaoAtiva(true);
          axios
            .get(
              "https://unimedsudoeste.net.br/votacao/rstObterCandidato.rule?sys=VOT"
            )
            .then((response) => {
              setCandidatos(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setVotacaoAtiva(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const votar = () => {
    axios
      .post("https://unimedsudoeste.net.br/votacao/rstVotar.rule?sys=VOT", {
        crm_cooperado: getJurado(),
        crm_candidato: candidatoSelecionado.crm,
        tipo_votacao: "T",
        token: getToken(),
      })
      .then((response) => {
        console.log(response);
        setResponse(response);
        setAlertSucess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderCard = (item, index) => {
    return (
      <button
        key={index}
        className="div-buttons"
        onClick={() => {
          setCandidatoSelecionado(item);
          setAlertDialog(true);
        }}
      >
        {item.nome === "BRANCO" ? (
          <div className="div-card-branco" key={index}>
            <GiVote size={35} />
            <div className="div-item">{item.nome}</div>
          </div>
        ) : (
          <div className="div-card" key={index}>
            <GiVote size={35} />
            <div className="div-item">{item.nome}</div>
          </div>
        )}
      </button>
    );
  };

  const closeAlertDialog = () => {
    setAlertDialog(false);
  };
  const closeAlertSucess = () => {
    setAlertSucess(false);
  };

  return (
    <div>
      {alertDialog && (
        <AlertDialog
          open={alertDialog}
          onSubmit={votar}
          onClose={closeAlertDialog}
          nomeCandidato={candidatoSelecionado.nome}
        />
      )}
      {alertSucess && (
        <AlertSucess
          open={alertSucess}
          response={response}
          onClose={closeAlertSucess}
        />
      )}
      <div className="button-float">
        <FiLogOut className="button-icon" fontSize={30} onClick={logout} />
      </div>
      {votacaoAtiva === undefined ? (
        <div></div>
      ) : (
        <div className="header">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          {votacaoAtiva ? (
            <>
              <h2 className="h2">Clique no nome do candidato para votar</h2>
              <FlatList
                list={candidatos}
                renderItem={renderCard}
                renderWhenEmpty={() => <div>Carregando candidatos...</div>}
              />
            </>
          ) : (
            <h2 className="h2">Votação ENCERRADA!</h2>
          )}
        </div>
      )}
    </div>
  );
}

export default Votacao;
