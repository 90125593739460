export const JURADO = "jurado";
export const TOKEN = "token";

export const jurado = jurado => {
  localStorage.setItem(JURADO, jurado);
};
export const getJurado = () => localStorage.getItem(JURADO);

export const token = token => {
  localStorage.setItem(TOKEN, token);
};
export const getToken = () => localStorage.getItem(TOKEN);

export const isAuthenticated = () => (localStorage.getItem(JURADO) !== null && localStorage.getItem(TOKEN) !== null);