import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { MdError } from "react-icons/md";
import "./style.css";

export const Alert = ({ open, onClose, text }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <div className={"div-alert"}>
          <MdError color={'#EEAD2D'} size={34} className={"icon-alert"} />
          <div>{text}</div>
        </div>
      </DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={() => {
          onClose();
        }} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
