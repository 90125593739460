import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import "./style.css";

export const AlertDialog = ({
  open,
  onSubmit,
  onClose,
  nomeCandidato
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{`Confirma o voto para ${nomeCandidato}?`}</DialogTitle>
      <DialogContent>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} autoFocus>
          Não
        </Button>
        <Button color="secondary" onClick={() => {
          onSubmit();
          onClose();
        }}>
         Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};