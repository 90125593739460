import React, { useState } from "react";
import "../../styles.css";
import { MdLock } from "react-icons/md";
import { FaUserMd } from "react-icons/fa";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { jurado, token } from "../../services/auth";
import { useHistory } from "react-router";
import { Alert } from "../../components/Alert";
import axios from "axios";
import logo from "../../images/logo_unimed.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [openAlertVazio, setOpenAlertVazio] = useState(false);
  const [openAlertErro, setOpenAlertErro] = useState(false);
  const [openAlertErroServidor, setOpenAlertErroServidor] = useState(false);
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const closeAlertVazio = () => {
    setOpenAlertVazio(false);
  };

  const closeAlertErro = () => {
    setOpenAlertErro(false);
  };

  const closeAlertErroServidor = () => {
    setOpenAlertErroServidor(false);
  };

  const handleSignIn = async (e) => {
    if (!email || !password) {
      setOpenAlertVazio(true);
    } else {
      let url = 'http://191.35.130.138:2019/app/Engine.rule?sys=APP&modulo=ger&acao=goIn&parametro=';
      let encoded = encodeURI(`{"login":"${email}","senha" : "${password}","versao":"0.1.28"}`);
      axios
        .get(url+encoded)
        .then((response) => {
         if (response.data.retorno) {
            jurado(response.data.dadosCooperado.conselhoNumero);
            token(response.data.usr_cripto);
            history.push("/votacao");
          } else {
            setOpenAlertErro(true);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setOpenAlertErroServidor(true);
        });
    }
  };

  return (
    <div className="login">
      {openAlertVazio && (
        <Alert
          open={openAlertVazio}
          onClose={closeAlertVazio}
          text="Preencha login e senha para continuar!"
        />
      )}
      {openAlertErro && (
        <Alert
          open={openAlertErro}
          onClose={closeAlertErro}
          text="Login ou senha inválidos!"
        />
      )}
      {openAlertErroServidor && (
        <Alert
          open={setOpenAlertErroServidor}
          onClose={closeAlertErroServidor}
          text="Não foi possível se comunicar com o servidor!"
        />
      )}
      <div className={"login-logo"}>
            <img src={logo} alt="Votação do Conselho Fiscal" />
         </div>
      <div className="login-right">
        <div className="title">Votação do Conselho Fiscal</div>
        <div className="login-loginInputEmail">
          <FaUserMd />
          <input
            type="email"
            placeholder="Digite seu CRM"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="login-loginInputPassword">
          <MdLock />
          <input
            placeholder="Digite sua senha"
            type={show ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="login-eye">
            {show ? (
              <HiEye size={20} onClick={handleClick} />
            ) : (
              <HiEyeOff size={20} onClick={handleClick} />
            )}
          </div>
        </div>
        <button
          type="submit"
          className="link"
          onClick={() => {
            handleSignIn();
          }}
        >
          Entrar
        </button>
      </div>
    </div>
  );
}

export default Login;
